body {

  .klaro {

    .cookie-notice, .cm-modal, .context-notice {
      background: black !important;
      border-radius: 0 !important;

      p {
        color: white;
      }

      a {
        color: $gradientpurple1;
        transition: color 0.25s;

        &:hover,
        &:focus,
        &:active {
          color: $gradientpurple2;
          transition: color 0.25s;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var {
        border-radius: 0;
        background-image: $purplegradientbutton;
      }

      .cm-btn.cn-decline {
        border-radius: 0;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $purplegradient;
      }

      h1.title {
        letter-spacing: 0;
      }
    }
  }

  &.color-0 {

    .klaro .cookie-notice, .cm-modal {

        a {
          color: $gradientpurple1;

          &:hover,
          &:focus,
          &:active {
            color: $gradientpurple2;
          }
        }

        .cm-btn.cm-btn-success,
        .cm-btn.cm-btn-success-var  {
          background-image: $purplegradientbutton;
        }

        .cm-list-input:checked + .cm-list-label .slider {
          background-image: $purplegradient;
        }
      }
  }

  &.color-1 {

    .klaro .cookie-notice, .cm-modal {

      a {
        color: $gradientred1;

        &:hover,
        &:focus,
        &:active {
          color: $gradientred2;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var {
        background-image: $redgradientbutton;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $redgradient;
      }
    }
  }

  &.color-2 {

    .klaro .cookie-notice, .cm-modal {

      a {
        color: $gradientorange1;

        &:hover,
        &:focus,
        &:active {
          color: $gradientorange2;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var  {
        background-image: $orangegradientbutton;
        color: $darkgreyfont;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $orangegradient;
      }
    }
  }

  &.color-3 {

    .klaro .cookie-notice, .cm-modal {

      a {
        color: $gradientyellow1;

        &:hover,
        &:focus,
        &:active {
          color: $gradientyellow2;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var  {
        background-image: $yellowgradientbutton;
        color: $darkgreyfont;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $yellowgradient;
      }
    }
  }

  &.color-4 {

    .klaro .cookie-notice, .cm-modal {

      a {
        color: $gradientgreen1;

        &:hover,
        &:focus,
        &:active {
          color: $gradientgreen2;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var  {
        background-image: $greengradientbutton;
        color: $darkgreyfont;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $greengradient;
      }
    }
  }

  &.color-5 {

    .klaro .cookie-notice, .cm-modal {

      a {
        color: $gradientblue1;

        &:hover,
        &:focus,
        &:active {
          color: $gradientblue2;
        }
      }

      .cm-btn.cm-btn-success,
      .cm-btn.cm-btn-success-var  {
        background-image: $bluegradientbutton;
      }

      .cm-list-input:checked + .cm-list-label .slider {
        background-image: $bluegradient;
      }
    }
  }
}
