// cover base

.cover {
  margin-top: -6rem;
  overflow: hidden;

  .cover_container {
    position: relative;
    width: 100%;
    height: 40rem;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .cover_mask {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
    }

    .cover_headlines {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 25rem);
      z-index: 4;

      h1 {
        max-width: 50rem;
        margin: auto;
        text-transform: uppercase;
      }
    }

    .button {
      position: absolute;
      bottom: 6rem;
      right: 16rem;
      z-index: 5;
      opacity: 0.9;

      text {
        font-family: $fontextrabold;
        fill: white;
        font-size: 1.125rem;
        letter-spacing: .2em;
      }
    }
  }
}