// image_textbox base

.image_textbox {
  position: relative;
  padding: 6rem 3rem;

  .textbox {
    position: relative;
    width: 100%;
    max-width: 56rem;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    z-index: 1;

    .textbox_header {
      position: relative;
      width: 50%;

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: left;
        font-size: 3rem;
        letter-spacing: 0;
        margin: 0;
        max-width: calc(100% - 4rem);

        em {
          color: white;
          letter-spacing: 0;
          margin: 0;
          font-size: 4rem;
          max-width: 100%;
        }
      }
    }

    .textbox_text {
      width: 50%;
      background-color: white;
      padding: 4rem 3rem;
      text-align: left;

      h1, h2, h3, h4, h5, h6 {
        font-family: $fontblack;
        font-size: 1.25rem;
        letter-spacing: 0;
        text-transform: uppercase;
        margin-bottom: 1em;
        text-align: left;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p, ul {
        margin-bottom: 3rem;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin-left: 1rem;
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
}