// map base

.map {
  position: relative;
  line-height: 1.25rem;

  iframe {
    border: none;
    height: 50vh;
    width: 100%;
    //    filter: grayscale(100%);
  }
}