//footer base

.footer {
  color: white;
  background-color: $darkgreyfont;

  .footer_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 75rem;
    margin: auto;
    padding: 2rem 0 0;

    div {
      width: 25%;
      padding: 3rem 1.5rem;

      &:first-of-type {
        align-self: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      h4 {
        text-transform: uppercase;
        font-size: 1.125rem;
        margin-bottom: 1.5rem;

        a {
          width: 100%;
          color: white;

          &:hover,
          &:focus,
          &:active {
            color: $lightgreyfont;
            text-decoration: none;
            outline: none;
          }
        }
      }

      p {
        line-height: normal;
        font-size: 0.8rem;
        margin-bottom: 0;

        a {
          color: $gradientpurple1;

          &:hover,
          &:focus,
          &:active {
            color: $gradientpurple2;
            text-decoration: none;
          }
        }
      }
    }
  }

  .legal_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 75rem;
    margin: auto;
    padding: 2rem 0 5rem 0;

    li {
      list-style: none;
      padding: 0 2rem;

      a {
        font-family: $fontregular;
        font-size: 1.125rem;
        color: white;
        text-transform: uppercase;
        letter-spacing: .2em;
      }

      &.active.purple a {
        color: $purplefont;
      }
      &.active.blue a {
        color: $bluefont;
      }
      &.active.green a {
        color: $greenfont;
      }
      &.active.yellow a {
        color: $yellowfont;
      }
      &.active.orange a {
        color: $orangefont;
      }
      &.active.red a {
        color: $redfont;
      }

      &.purple a {

        &:hover,
        &:focus,
        &:active {
          color: $purplefont;
        }
      }
      &.blue a {

        &:hover,
        &:focus,
        &:active {
          color: $bluefont;
        }
      }
      &.green a {

        &:hover,
        &:focus,
        &:active {
          color: $greenfont;
        }
      }
      &.yellow a {

        &:hover,
        &:focus,
        &:active {
          color: $yellowfont;
        }
      }
      &.orange a {

        &:hover,
        &:focus,
        &:active {
          color: $orangefont;
        }
      }
      &.red a {

        &:hover,
        &:focus,
        &:active {
          color: $redfont;
        }
      }

      &:hover,
      &:focus,
      &:active {

        a {
          text-decoration: none;
        }
      }
    }
  }
}

body.color-0 {

  .footer .footer_container div p a {
    color: $gradientpurple1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientpurple2;
      text-decoration: none;
    }
  }
}

body.color-1 {

  .footer .footer_container div p a {
    color: $gradientred1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientred2;
      text-decoration: none;
    }
  }
}

body.color-2 {

  .footer .footer_container div p a {
    color: $gradientorange1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientorange2;
      text-decoration: none;
    }
  }
}

body.color-3 {

  .footer .footer_container div p a {
    color: $gradientyellow1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientyellow2;
      text-decoration: none;
    }
  }
}

body.color-4 {

  .footer .footer_container div p a {
    color: $gradientgreen1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientgreen2;
      text-decoration: none;
    }
  }
}

body.color-5 {

  .footer .footer_container div p a {
    color: $gradientblue1;

    &:hover,
    &:focus,
    &:active {
      color: $gradientblue2;
      text-decoration: none;
    }
  }
}

