@font-face {
  font-family: "AvallonAlt";
  src: url("../../Public/fonts/AVALLONALT.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/AVALLONALT.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  //url("../../Public/fonts/AVALLONALT.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/AVALLONALT.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/AVALLONALT.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/AVALLONALT.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/AVALLONALT.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("../../Public/fonts/MONTSERRAT-BLACK.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-BLACK.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-BLACK.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-BLACK.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-BLACK.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-BLACK.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-BLACK.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-EXTRABOLD0.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-EXTRALIGHT0.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("../../Public/fonts/MONTSERRAT-LIGHT.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-LIGHT.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-LIGHT.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-LIGHT.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-LIGHT.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-LIGHT.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-LIGHT.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../Public/fonts/MONTSERRAT-REGULAR.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-REGULAR.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-REGULAR.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-REGULAR.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-REGULAR.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-REGULAR.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-REGULAR.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.eot"); /* IE9 Compat Modes */
  src: url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.otf") format("opentype"), /* Open Type Font */
  url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.svg") format("svg"), /* Legacy iOS */
  url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.woff") format("woff"), /* Modern Browsers */
  url("../../Public/fonts/MONTSERRAT-SEMIBOLD0.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


