// menu base

nav {
  position: absolute;
  top: 0;
  z-index: 100;
  transition: background-color 0.5s;

  .main.navbar {
    font-family: $fontregular;
    font-size: 1.125rem;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s;
    height: 6rem;
    max-width: 75rem;
    margin: auto;
    padding: 0;

    .navbar-brand {
      margin-right: 3rem;
      margin-top: -0.4rem;

      img {
        display: inline-block;
        width: 10rem;
        height: auto;
      }
    }

    .navbar-toggler {
      margin: 1.75rem 0;
      border-color: rgba(255,255,255, 0.5);
    }

    .navbar-collapse {
      background-color: rgba(0, 0, 0, 0);
      padding: 0 1rem;
      margin: 0 -1rem;
      transition: background-color, height;
      transition-duration: 1s;

      @media screen and (max-width: 61.9375rem) {
        max-height: calc(100vh - 6rem);
        overflow-y: scroll;
      }

      .navbar-nav {
        -ms-flex-pack: justify;
        justify-content: space-between !important;
        width: 100%;
        height: 6rem;

        .nav-item {
          position: relative;
          width: 100%;
          height: 100%;

          >a {
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

          .dropdown-menu {
            background-color: rgba(0,0,0,0.0);
            transition: background-color 0.5s;
            margin-left: -1rem;
            border: none;
            border-radius: 0;
            margin-top: 0;

            li {

              &:hover,
              &:focus,
              &:active {
                background-color: unset;

                a {
                  text-decoration: none;
                }
              }

              &.active {
                background-color: transparent;

                a {
                  color: $purplefont;
                }

                &.purple > a {
                  color: $purplefont;
                }
                &.blue > a {
                  color: $bluefont;
                }
                &.green > a {
                  color: $greenfont;
                }
                &.yellow > a {
                  color: $yellowfont;
                }
                &.orange > a {
                  color: $orangefont;
                }
                &.red > a {
                  color: $redfont;
                }
              }

              &.line {
                display: block;
                margin: 1rem 1.5rem 0;
                width: 5rem;
                height: 0.0625rem;

                &.purple {
                  background-color: $gradientpurple1;
                }
                &.red {
                  background-color: $gradientred1;
                }
                &.orange {
                  background-color: $gradientorange1;
                }
                &.yellow {
                  background-color: $gradientyellow1;
                }
                &.green {
                  background-color: $gradientgreen1;
                }
                &.blue {
                  background-color: $gradientblue1;
                }
              }
            }
          }

          &.active > a {
            color: $purplefont;
            text-transform: uppercase;
            letter-spacing: .2em;
            font-weight: normal;
          }

          &.active.purple > a {
            color: $purplefont;
          }
          &.active.blue > a {
            color: $bluefont;
          }
          &.active.green > a {
            color: $greenfont;
          }
          &.active.yellow > a {
            color: $yellowfont;
          }
          &.active.orange > a {
            color: $orangefont;
          }
          &.active.red > a {
            color: $redfont;
          }

          &.purple a {

            &:hover,
            &:focus,
            &:active {
              color: $purplefont;
            }
          }
          &.blue a {

            &:hover,
            &:focus,
            &:active {
              color: $bluefont;
            }
          }
          &.green a {

            &:hover,
            &:focus,
            &:active {
              color: $greenfont;
            }
          }
          &.yellow a {

            &:hover,
            &:focus,
            &:active {
              color: $yellowfont;
            }
          }
          &.orange a {

            &:hover,
            &:focus,
            &:active {
              color: $orangefont;
            }
          }
          &.red a {

            &:hover,
            &:focus,
            &:active {
              color: $redfont;
            }
          }
        }
      }
    }

    .navbar-brand span,
    .navbar-collapse .navbar-nav .nav-item a {
      color: white;
      text-transform: uppercase;
      letter-spacing: .2em;
      white-space: nowrap;

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.bg_black {
  background-color: rgba(0, 0, 0, 0.8) !important;
  transition: background-color 0.5s;
}
