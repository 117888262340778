// divider_cover base

.divider_cover {
  margin-top: 0;
  overflow: hidden;

  .divider_cover_container {
    position: relative;
    width: 100%;
    height: 35rem;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .divider_cover_mask {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
    }

    .divider_cover_headlines {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 25rem);
      z-index: 4;

      h2 {
        font-family: $fontlight;
        max-width: 50rem;
        margin: auto;
        text-align: left;
        color: white;
        font-size: 3rem;

        em {
          color: white;
          font-size: 4rem;
          //hyphens: auto;
        }
      }
    }

    .button {
      position: absolute;
      bottom: 3rem;
      right: 14rem;
      z-index: 5;
      opacity: 0.9;

      text {
        font-family: $fontextrabold;
        fill: white;
        font-size: 1.125rem;
        letter-spacing: .2em;
      }
    }
  }
}