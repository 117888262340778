// faq base

.faq {
  padding: 6rem 1rem 4rem;

  .faq_container {
    max-width: 75rem;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;

    .faq_image {
      position: relative;
      width: 50%;
      height: calc(50vw - 6rem);
      max-height: 36rem;
      padding-right: 4rem;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;

        text {
          font-family: Montserrat-Light, sans-serif;
          text-transform: uppercase;
        }
      }
    }

    .faq_text {
      width: 50%;

      h2 {
        text-align: left;
        margin-bottom: 2rem;
        margin-top: 0;

        em {
          letter-spacing: 0;
        }

      }

      ul {
        margin-left: 1rem;
      }

      .accordion {
        margin: 3rem 0 0;

        .card {
          border: none;

          .card-header {
            background-color: transparent;
            border-bottom: none;
            padding: .75rem 1.25rem .75rem 0;

            a {
              text-decoration: none;

              p {
                  font-family: Montserrat-SemiBold,sans-serif;
                  font-size: 1.125rem;
                  text-transform: uppercase;
              }

              .faq-question {
                margin: 0;
                display: -ms-flexbox;
                display: flex;

                &::before {
                  font-family: 'Font Awesome 5 Free';
                  content: "\f068";
                  font-weight: 900;
                  margin-right: 0.5rem;
                }
              }

              &.collapsed {

                .faq-question {
                  color: $darkgreyfont;

                  &::before {
                    content: "\f067";
                  }
                }

                &:hover,
                &:focus,
                &active {

                  .faq-question {
                    color: $lightgreyfont;
                  }
                }
              }
            }
          }

          .collapse, .collapsing {

            .card-body {
              padding: 0.5rem 0 1.5rem 1.75rem;

              p {
                font-size: 1rem;
              }
            }
          }
        }
      }

      .standard_button {
        margin-top: 3rem;
      }
    }
  }
}
