//default base



* {
//  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;

  body {
    font-family: Montserrat-Regular, sans-serif;

    h1 {
      font-family: $fontlight;
      font-size: 3.75rem;
      letter-spacing: .2em;
      color: white;

      em {
        display: block;
        font-family: $fontblack;
        font-size: 4.5rem;
        font-style: normal;
      }
    }

    h2 {
      font-family: $fontregular;
      font-size: 1.125rem;
      letter-spacing: .2em;
      color: $lightgreyfont;
      text-transform: uppercase;
      text-align: center;
      margin-top: 4rem;

      em {
        display: block;
        font-family: $fontblack;
        font-size: 3rem;
        letter-spacing: .2em;
        color: $darkgreyfont;
        font-style: normal;
        margin-top: 0.5rem;
      }
    }

    h3 {
      font-family: $fontbold;
      font-size: 1.125rem;
      text-transform: uppercase;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: $fontregular;
      font-size: 1rem;
      line-height: 1.75em;
    }

    .btn.btn-primary,
    .btn.btn-cancel {
      background-color: unset;
      border-color: unset;
      border: none;
      font-weight: unset;
      text-align: unset;
      vertical-align: unset;
      line-height: unset;
      border-radius: unset;
      transition: unset;
      font-family: $fontextrabold;
      font-size: 1.125rem;
      letter-spacing: .2em;
      display: inline-block;
      margin: 0 auto;
      color: white;
      padding: 1.5rem 2rem;
      margin-top: 1rem;
      margin-right: 1rem;
    }

    .standard_button {
      font-family: $fontextrabold;
      font-size: 1.125rem;
      letter-spacing: .2em;
      display: inline-block;
      margin: 0 auto;
      color: white;
      padding: 1.6875rem 3.5rem;
      border: none;
    }

    .blog_button {
      font-family: $fontblack;
      font-size: 1.125rem;
      letter-spacing: .2em;
      display: inline-block;
      margin: 0 auto;
      color: white;
      padding: 2rem 4rem;
      border: none;
    }

    .hollow_button {
      font-family: $fontextrabold;
      font-size: 1.125rem;
      letter-spacing: .2em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.9);
      border: 0.35rem solid white;
      background-color: rgba(255, 255, 255, 0);
      padding: 0.75rem 1.75rem;
      transition: color 0.5s, background-color 0.5s;
      white-space: nowrap;

      &:hover,
      &:focus,
      &:active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 0.2);
        transition: color 0.5s, background-color 0.5s;
        text-decoration: none;
      }
    }






    // color themes


    //defaults without bodyclass

    .theme-color-1 {
      color: $gradientpurple1;
    }
    a .theme-color-1 {
      &:hover, &:focus, &:active {
        color: $gradientpurple2;
      }
    }
    .theme-color-2 {
      color: $gradientpurple2;
    }
    .theme-background-1 {
      background-color: $gradientpurple1;
    }
    .theme-background-2 {
      background-color: $gradientpurple2;
    }
    .theme-gradient {
      background-image: $purplegradient;
    }
    .theme-overlay {
      background-image: $purplegradientoverlay;
    }
    .theme-button,
    .btn.btn-primary,
    .btn.btn-cancel {
      background-image: $purplegradientbutton;
    }
    .theme-blog-button {
      background-image: $purplegradientblogbutton;
    }
    .theme-hover {
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-image: $purplegradientoverlay;
      }
    }
    .theme-hover-mobile {
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-image: $purplegradientoverlay;
      }
      @media screen and (max-width: 42rem) {
        background-image: $purplegradientoverlay;
      }
    }
    .svg-theme-gradient {
      defs {
        stop[offset="0%"] {
          stop-color: $gradientpurple1;
          stop-opacity: 1;
        }
        stop[offset="100%"] {
          stop-color: $gradientpurple2;
          stop-opacity: 1;
        }
      }
    }

    //schemes if bodyclass

    &.color-0 {
      .theme-color-1 {
        color: $gradientpurple1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientpurple2;
        }
      }
      .theme-color-2 {
        color: $gradientpurple2;
      }
      .theme-background-1 {
        background-color: $gradientpurple1;
      }
      .theme-background-2 {
        background-color: $gradientpurple2;
      }
      .theme-gradient {
        background-image: $purplegradient;
      }
      .theme-overlay {
        background-image: $purplegradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $purplegradientbutton;
      }
      .theme-blog-button {
        background-image: $purplegradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $purplegradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $purplegradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $purplegradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientpurple1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientpurple2;
            stop-opacity: 1;
          }
        }
      }
    }
    &.color-1 {
      .theme-color-1 {
        color: $gradientred1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientred2;
        }
      }
      .theme-color-2 {
        color: $gradientred2;
      }
      .theme-background-1 {
        background-color: $gradientred1;
      }
      .theme-background-2 {
        background-color: $gradientred2;
      }
      .theme-gradient {
        background-image: $redgradient;
      }
      .theme-overlay {
        background-image: $redgradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $redgradientbutton;
      }
      .theme-blog-button {
        background-image: $redgradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $redgradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $redgradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $redgradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientred1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientred2;
            stop-opacity: 1;
          }
        }
      }
    }
    &.color-2 {
      .theme-color-1 {
        color: $gradientorange1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientorange2;
        }
      }
      .theme-color-2 {
        color: $gradientorange2;
      }
      .theme-background-1 {
        background-color: $gradientorange1;
      }
      .theme-background-2 {
        background-color: $gradientorange2;
      }
      .theme-gradient {
        background-image: $orangegradient;
      }
      .theme-overlay {
        background-image: $orangegradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $orangegradientbutton;
      }
      .theme-blog-button {
        background-image: $orangegradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $orangegradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $orangegradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $orangegradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientorange1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientorange2;
            stop-opacity: 1;
          }
        }
      }
    }
    &.color-3 {
      .theme-color-1 {
        color: $gradientyellow1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientyellow2;
        }
      }
      .theme-color-2 {
        color: $gradientyellow2;
      }
      .theme-background-1 {
        background-color: $gradientyellow1;
      }
      .theme-background-2 {
        background-color: $gradientyellow2;
      }
      .theme-gradient {
        background-image: $yellowgradient;
      }
      .theme-overlay {
        background-image: $yellowgradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $yellowgradientbutton;
      }
      .theme-blog-button {
        background-image: $yellowgradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $yellowgradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $yellowgradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $yellowgradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientyellow1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientyellow2;
            stop-opacity: 1;
          }
        }
      }
    }
    &.color-4 {
      .theme-color-1 {
        color: $gradientgreen1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientgreen2;
        }
      }
      .theme-color-2 {
        color: $gradientgreen2;
      }
      .theme-background-1 {
        background-color: $gradientgreen1;
      }
      .theme-background-2 {
        background-color: $gradientgreen2;
      }
      .theme-gradient {
        background-image: $greengradient;
      }
      .theme-overlay {
        background-image: $greengradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $greengradientbutton;
      }
      .theme-blog-button {
        background-image: $greengradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $greengradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $greengradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $greengradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientgreen1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientgreen2;
            stop-opacity: 1;
          }
        }
      }
    }
    &.color-5 {
      .theme-color-1 {
        color: $gradientblue1;
      }
      a .theme-color-1 {
        &:hover, &:focus, &:active {
          color: $gradientblue2;
        }
      }
      .theme-color-2 {
        color: $gradientblue2;
      }
      .theme-background-1 {
        background-color: $gradientblue1;
      }
      .theme-background-2 {
        background-color: $gradientblue2;
      }
      .theme-gradient {
        background-image: $bluegradient;
      }
      .theme-overlay {
        background-image: $bluegradientoverlay;
      }
      .theme-button, .btn.btn-primary, .btn.btn-cancel {
        background-image: $bluegradientbutton;
      }
      .theme-blog-button {
        background-image: $bluegradientblogbutton;
      }
      .theme-hover {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $bluegradientoverlay;
        }
      }
      .theme-hover-mobile {
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-image: $bluegradientoverlay;
        }
        @media screen and (max-width: 42rem) {
          background-image: $bluegradientoverlay;
        }
      }
      .svg-theme-gradient {
        defs {
          stop[offset="0%"] {
            stop-color: $gradientblue1;
            stop-opacity: 1;
          }
          stop[offset="100%"] {
            stop-color: $gradientblue2;
            stop-opacity: 1;
          }
        }
      }
    }
  }
}


