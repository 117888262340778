//calendar base

.calendar {
  padding: 4rem 1rem 0;

  .calendar_container {
    max-width: 75rem;
    margin: 0 auto;

    .calendar_person {
      display: -ms-flexbox;
      display: flex;

      .calendar_person_image_container {
        width: 100%;
        padding-right: 4rem;

        .calendar_person_image {
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }

      .calendar_person_text {
        width: 65%;
        max-width: 65%;
        min-width: 65%;

        h2 {
          margin-top: 0;
          text-align: left;
          margin-bottom: 2rem;
        }

        .calendar_social {
          display: -ms-flexbox;
          display: flex;
          margin-top: 3rem;

          a {
            margin-right: 2rem;
            text-decoration: none;

            .dummy, img {
              height: 3.5rem;
              width: 3.5rem;
              object-fit: cover;
              border-radius: 50%;
              line-height: 3.5rem;
              text-align: center;
              color: white;
            }

            i {
              height: 3.5rem;
              width: 3.5rem;
              font-size: 2.25rem;
              line-height: 3.5rem;
              border-radius: 50%;
              text-align: center;
              color: white;
            }
          }
        }
      }
    }
  }
}