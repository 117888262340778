// blog_detail base

.blog_detail {
  margin-top: -6rem;

  .blog_header {
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 90vh;
      object-fit: cover;
    }

    h1 {
      font-family: $fontblack;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;
      max-width: 75rem;

      span {
        display: inline-block;
        background-color: black;
        font-size: 1.5rem;
        font-family: $fontbold;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  .blog_detail_container {
    padding: 0 2rem;

    h3 {
      margin-top: 0;
    }

    p {
      text-align: justify;
      margin: 0;
    }

    ul {
      margin: 1rem 0 1rem 1rem;
    }

    .blog_detail_part {

      .quote {

        i {
          display: block;
          margin-bottom: 2.5rem;
          font-size: 2.5rem;
        }

        p {
          color: $orangefont;
          font-family: $fontextrabold;
          font-style: italic;
          font-size: 2.5rem;
          text-align: left;
          line-height: 1.35;
        }
      }

      .image {

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .blog_detail_imageonly {
        max-width: 75rem;
        margin: 4rem auto;

        img {
          width: calc(100% + 10rem);
          height: auto;
          margin-left: -10rem;
        }

        &.imagedivider {
          max-width: calc(100% + 4rem);
          margin-left: -2rem;
          margin-right: -2rem;

          img {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .blog_detail_textonly {
        max-width: 75rem;
        margin: 4rem auto;

      }

      .blog_detail_quoteonly {
        max-width: 75rem;
        margin: 4rem auto;

        i {
          display: block;
          margin-bottom: 2.5rem;
          font-size: 2.5rem;
        }

        p {
          color: $orangefont;
          font-family: $fontextrabold;
          font-style: italic;
          font-size: 2.5rem;
          text-align: left;
          line-height: 1.35;
        }
      }

      .blog_detail_imageandtext {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        max-width: 75rem;
        margin: 4rem auto;

        .image {
          position: relative;
          width: 60%;

          img {
            position: absolute;
            height: 100%;
            width: calc(100% + 10rem);
            object-fit: cover;
            margin-left: -10rem;
            padding-right: 2rem;
          }
        }

        .text {
          width: 40%;
        }
      }

      .blog_detail_textandquote {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        max-width: 75rem;
        margin: 4rem auto;

        .quote {
          width: 40%;
          padding-left: 5rem;

          p {
            text-align: right;
          }

          i {
            text-align: right;

            &::before {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }

        .text {
          width: 60%;
        }

        &.quoteleft {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;

          .quote {
            padding-left: 0;
            padding-right: 5rem;

            p {
              text-align: left;
            }

            i {
              text-align: left;

              &::before {
                display: inline-block;
                transform: rotate(0);
              }
            }
          }
        }
      }

      .blog_detail_imageandquote {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        max-width: 75rem;
        margin: 4rem auto;

        .image {
          width: calc(60% + 10rem);
          position: relative;
          margin-left: -10rem;

          img {
            position: absolute;
            padding-right: 2rem;
          }
        }

        .quote {
          width: 40%;
          padding-left: 3rem;

          p {
            text-align: right;
          }

          i {
            text-align: right;

            &::before {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }

        &.quoteleft {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;

          .quote {
            padding-left: 0;
            padding-right: 5rem;

            p {
              text-align: left;
            }

            i {
              text-align: left;

              &::before {
                transform: rotate(0);
              }
            }
          }

          .image {
            margin-left: 0;
            width: 60%;

            img {
              padding-right: 0;
            }
          }
        }
      }

      .blog_detail_author {
        border-top: 0.125rem solid $lightgreyfont;
        padding-top: 2rem;
        max-width: 75rem;
        margin: 4rem auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;

        img, .dummy {
          width: 12rem;
          height: 12rem;
          border-radius: 50%;
        }

        .text {
          padding-left: 2rem;
        }
      }
    }
  }
}