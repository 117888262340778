//logo_gallery base

.logo_gallery {
  padding: 6rem 1rem;

  .logo_gallery_container {
    max-width: 75rem;
    margin: 0 auto;

    h2 {

      em {
        margin-bottom: 1em;
      }
    }

    p {
      text-align: center;
    }

    .logo_gallery_images {
      margin-top: 5rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;

      .logo_gallery_image_container {
        width: 20%;
        height: 10rem;
        padding: 2rem 2.5%;

        img {
          filter: grayscale(100%);
          opacity: 0.5;
          width: 100%;
          height: 100%;
          object-fit: contain;

          &:hover,
          &:focus,
          &:active {
            filter: grayscale(0%);
            opacity: 1;
          }
        }
      }
    }
  }
}