// reference_slider base

.reference_slider {
  position: relative;
  margin: 8rem 0 5rem;

  .reference_slider_container {
    position: relative;
    max-width: 75rem;
    margin: auto;
    overflow: hidden;

    .reference_slider_items {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      height: 5rem;
      overflow: unset;

      .reference_slider_item {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
      }

      img {
        padding: 0 10%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: grayscale(100%) opacity(50%);
      }
    }
  }
}