// image_text base

.image_and_text {
  padding: 4rem 1rem;

  .image_and_text_container {
    max-width: 75rem;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;

    .image_and_text_image {
      position: relative;
      width: 50%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding-right: 2rem;
      }
    }

    .image_and_text_text {
      width: 50%;

      h2 {
        text-align: left;
        margin-bottom: 2rem;

        em {
          letter-spacing: 0;
        }

      }

      ul {
        margin-left: 1rem;
      }

      .standard_button {
        margin-top: 3rem;
      }
    }
  }
}