// gallery base

.gallery {
  padding: 8rem 1rem;

  .gallery_container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.875rem;
    max-width: 75rem;
    margin: 0 auto;

    a {
      position: relative;

      &:nth-of-type(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-row: 1/2;
        grid-column: 1/2;
      }

      &:nth-of-type(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-row: 1/2;
        grid-column: 2/3;
      }

      &:nth-of-type(3) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-row-span: 2;
        grid-row: 1/3;
        grid-column: 3/4;
      }

      &:nth-of-type(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        grid-row: 2/3;
        grid-column: 1/2;
      }

      &:nth-of-type(5) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        -ms-grid-row-span: 2;
        grid-row: 2/4;
        grid-column: 2/3;
      }

      &:nth-of-type(6) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        grid-row: 3/4;
        grid-column: 1/2;
      }

      &:nth-of-type(7) {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
        grid-row: 3/4;
        grid-column: 3/4;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }

      .overlay {
        opacity: 0;
        transition: opacity 0.25s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &::after {
        opacity: 0;
        transition: opacity 0.25s;
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        content: "\f055";
        font-weight: 900;
        font-size: 2rem;
        color: white;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover,
      &:focus,
      &:active {

        .overlay,
        &::after {
          opacity: 1;
          transition: opacity 0.25s;
        }
      }
    }
  }
}