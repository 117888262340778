//purple, default

//colors
$gradientpurple1:           #fd3367;
$gradientpurple2:           #663399;
//gradients
$purplegradient:            linear-gradient(32deg, $gradientpurple1, $gradientpurple2);
$purplegradientoverlay:     linear-gradient(32deg, rgba($gradientpurple1, 0.5), rgba($gradientpurple2, 0.5));
$purplegradientbutton:      linear-gradient(45deg, $gradientpurple1, $gradientpurple2);
$purplegradientblogbutton:  linear-gradient(-9deg, $gradientpurple1, $gradientpurple2);


//red

//colors
$gradientred1:              #e21c34;
$gradientred2:              #500b28;
//gradients
$redgradient:               linear-gradient(32deg, $gradientred1, $gradientred2);
$redgradientoverlay:        linear-gradient(32deg, rgba($gradientred1, 0.5), rgba($gradientred2, 0.5));
$redgradientbutton:         linear-gradient(45deg, $gradientred1, $gradientred2);
$redgradientblogbutton:     linear-gradient(-9deg, $gradientred1, $gradientred2);


//orange

//colors
$gradientorange1:           #fea858;
$gradientorange2:           #ed765e;
//gradients
$orangegradient:            linear-gradient(32deg, $gradientorange1, $gradientorange2);
$orangegradientoverlay:     linear-gradient(32deg, rgba($gradientorange1, 0.5), rgba($gradientorange2, 0.5));
$orangegradientbutton:      linear-gradient(45deg, $gradientorange1, $gradientorange2);
$orangegradientblogbutton:  linear-gradient(-9deg, $gradientorange1, $gradientorange2);


//yellow

//colors
$gradientyellow1:           #f9e866;
$gradientyellow2:           #ffae00;
//gradients
$yellowgradient:            linear-gradient(32deg, $gradientyellow1, $gradientyellow2);
$yellowgradientoverlay:     linear-gradient(32deg, rgba($gradientyellow1, 0.5), rgba($gradientyellow2, 0.5));
$yellowgradientbutton:      linear-gradient(45deg, $gradientyellow1, $gradientyellow2);
$yellowgradientblogbutton: linear-gradient(-9deg, $gradientyellow1, $gradientyellow2);


//green

//colors
$gradientgreen1:            #b3eb50;
$gradientgreen2:            #0ea18f;
//gradients
$greengradient:             linear-gradient(32deg, $gradientgreen1, $gradientgreen2);
$greengradientoverlay:      linear-gradient(32deg, rgba($gradientgreen1, 0.5), rgba($gradientgreen2, 0.5));
$greengradientbutton:       linear-gradient(45deg, $gradientgreen1, $gradientgreen2);
$greengradientblogbutton:   linear-gradient(-9deg, $gradientgreen1, $gradientgreen2);


//blue

//colors
$gradientblue1:             #6698cc;
$gradientblue2:             #6634cc;
//gradients
$bluegradient:              linear-gradient(32deg, $gradientblue1, $gradientblue2);
$bluegradientoverlay:       linear-gradient(32deg, rgba($gradientblue1, 0.5), rgba($gradientblue2, 0.5));
$bluegradientbutton:        linear-gradient(45deg, $gradientblue1, $gradientblue2);
$bluegradientblogbutton:    linear-gradient(-9deg, $gradientblue1, $gradientblue2);



