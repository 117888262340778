// service base

.service {
  margin-bottom: 2rem;

  h3 {
    margin-top: 3rem;
  }

  .service_container {
    max-width: 75rem;
    margin: 4rem auto 0;
    display: -ms-flexbox;
    display: flex;
    //-ms-flex-pack: justify;
    -ms-flex-wrap: wrap;
    flex-flow: wrap;
    //justify-content: space-around;
    -ms-flex-align: stretch;
    align-items: stretch;

    .service_item {
      position: relative;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      width: calc(33.33% - 1.5rem);
      text-align: center;
      color: black;
      padding: 2rem;
      margin-bottom: 2rem;
      transition: box-shadow, 0.5s;
      text-decoration: none;

      .counter {
        width: 5rem;
        margin: auto;

        p {
          color: white;
          font-family: $fontcursive;
          font-size: 3rem;
          line-height: 4.25rem;
          font-weight: normal;
          background-color: black;
          transition: background-color, 0.5s;
        }
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        outline: none;
        box-shadow: 0 0 1.5rem $lightgreyfont;
        transition: box-shadow, 0.5s;

        .counter {

          p {
            transition: background-color, 0.5s;
            background-color: transparent;
          }
        }
      }
    }

    &.two_columns {
      .service_item {
        margin-left: 1rem;
        margin-right: 1rem;
        width: calc(50% - 2rem);
      }
    }

    &.three_columns {
      .service_item {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        width: calc(33.33% - 1.5rem);
      }
    }

    &.four_columns {
      .service_item {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: calc(25% - 1rem);
      }
    }
  }

  .service_carousel {
    display: none;
    position: relative;
    overflow: hidden;

    .carousel {

      .service_item {
        position: relative;
        padding: 2rem 10%;
        text-decoration: none;
        width: 100vw;

        .service_item_box {
          position: relative;
          width: 100%;
          text-align: center;
          color: black;
          padding: 2rem;
          box-shadow: 0 0 1.5rem $lightgreyfont;

          .counter {
            width: 5rem;
            margin: auto;

            p {
              color: white;
              font-family: $fontcursive;
              font-size: 2.75rem;
            }
          }
        }
      }
    }

    .carousel-control-prev, .carousel-control-next {
      width: 8%;
    }

    .carousel-control-next span, .carousel-control-prev span {
      width: 4em;
      height: 4em;
    }

    .carousel-control-prev span img {
      transform: rotate(180deg);
    }
  }
}