.frame.frame-default.frame-type-list.frame-layout-0 {
  padding: 4rem 1rem;

  .callrotation_container {
    max-width: 75rem;
    margin: auto;

    h2 {
      text-align: left;
      margin-bottom: 2rem;

      em {
        letter-spacing: 0;
      }
    }

    p {
      margin-bottom: 4rem;
    }

    .mobile_version {
      display: none;
    }

    .desktop_version {
      display: table;
    }

    .emergency_current {
      background-color: rgba(0,0,0,0.75);
      color: white;
      padding: 2rem;
      margin-bottom: 3rem;

      h3 {
        margin-top: 0;
      }

      h4 {
        font-family: Montserrat-SemiBold,sans-serif;
        font-size: 1rem;
        text-transform: uppercase;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
        opacity: 0.75;
      }

      .desktop_version {
        border: 0.125rem solid white;

        tr {

          td {
            padding: 0.25rem 0 0.25rem 1.5rem;
            color: white;
            white-space: nowrap;

            span {
              filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
            }

            &:last-of-type {
              padding-right: 1.5rem;
            }
          }

          &:first-of-type  td {
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-color: rgba(0,0,0,0.3);
          }

          &:nth-of-type(2) td {
            padding-top: 1rem;
          }

          &:last-of-type td {
            padding-bottom: 1rem;
          }
        }
      }
    }

    .emergency_next {
      padding: 2rem;

      h3 {
        margin-top: 0;
      }

      h4 {
        font-family: Montserrat-SemiBold,sans-serif;
        font-size: 1rem;
        text-transform: uppercase;
        margin-top: 2.5rem;
        margin-bottom: 0.75rem;
        opacity: 0.75;
      }

      table {
        border: 0.125rem solid white;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        tr {

          td {
            padding: 0.25rem 0 0.25rem 1.5rem;
            color: white;
            white-space: nowrap;

            span {
              filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
            }

            &:last-of-type {
              padding-right: 1.5rem;
            }
          }

          &:first-of-type  td {
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-color: rgba(0,0,0,0.3);
          }

          &:nth-of-type(2) td {
            padding-top: 1rem;
          }

          &:last-of-type td {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}