// blog base

.blog {
  max-width: 75rem;
  margin: 6rem auto 3rem;

  .blog_blocks {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 2fr 1fr 1fr;
    grid-template-columns: 2fr 1fr 1fr;
    -ms-grid-rows: 50% 50%;
    grid-template-rows: 50% 50%;
    height: 70vh;
    max-height: 60rem;
    width: 100%;
    margin: 4rem 0 3.5rem;

    .blog_block {
      display: block;
      position: relative;
      color: white;
      margin: 0.4rem;

      .blog_overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &:hover,
      &:focus,
      &active {

        .blog_blocks_content {
          background-image: none;
        }

        .blog_overlay {
          opacity: 1;
        }

      }

      &>img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }


      .blog_blocks_content {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 3rem 1.5rem 2rem;
        background-image: $blackgradientoverlay;
        z-index: 2;
        max-height: 100%;
        overflow: hidden;

        svg text {
          font-family: $fontregular;
          font-size: 1.125rem;
          text-transform: uppercase;
          color: white;
        }

        h3 {
          font-family: $fontblack;
          font-size: 1.875rem;
          text-transform: uppercase;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        &>div {
          margin-top: 1rem;

          &>img {
            display: inline-block;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            margin-right: 0.8rem;
          }

          p {
            display: inline-block;
            font-family: $fontextrabold;
            font-size: 1.125rem;
            vertical-align: bottom;
            margin-bottom: 0;
            line-height: 3.5rem;
          }
        }
      }
    }

    .blog_block:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      -ms-grid-row: 1;
      grid-column: 1/2;
      grid-row: 1/3;

      .blog_blocks_content {

        h3 {
          font-size: 1.875rem;
        }

        &>div {

          &>img {
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 0.8rem;
          }

          p {
            font-size: 1.125rem;
            line-height: 3.5rem;
          }
        }
      }
    }

    .blog_block:nth-child(2) {
      -ms-grid-column: 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      grid-column: 2/3;
      grid-row: 1/3;

      .blog_blocks_content {

        h3 {
          font-size: 1.65rem;
        }

        &>div {

          &>img {
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 0.8rem;
          }

          p {
            font-size: 1.125rem;
            line-height: 3.5rem;
          }
        }
      }
    }

    .blog_block:nth-child(3) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      grid-column: 3/4;
      grid-row: 1/2;

      .blog_blocks_content {
        padding-bottom: 1rem;

        h3 {
          font-size: 1.325rem;
        }

        &>div {

          &>img {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.8rem;
          }

          p {
            font-size: 1rem;
            line-height: 2.5rem;
          }
        }
      }
    }

    .blog_block:nth-child(4) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
      grid-column: 3/4;
      grid-row: 2/3;

      .blog_blocks_content {
        padding-bottom: 1rem;

        h3 {
          font-size: 1.325rem;
        }

        &>div {

          &>img {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.8rem;
          }

          p {
            font-size: 1rem;
            line-height: 2.5rem;
          }
        }
      }
    }
  }

  &>a {
    display: block;
    text-align: center;
  }
}