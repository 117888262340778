// team base

.team {

  h2 {
    margin-bottom: 3em;
  }

  .team_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .team_member {
      position: relative;
      width: 33.33%;
      max-width: 33.33%;
      min-width: 33.33%;
      height: 33.33vw;

      img, .dummy {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .team_member_overlay {
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &[data-aos="fade-team"] {
          opacity: 0;
          transition-property: transform, opacity;

          &.aos-animate {
            opacity: 0;
          }

          @media screen and (max-width: 36rem) {
            opacity: 0;
            transition-property: transform, opacity;

            &.aos-animate {
              opacity: 1;
            }
          }
        }

        .team_member_overlay_text {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          padding: 1rem;

          h3 {
            color: white;
            font-family: $fontblack;
            text-transform: uppercase;
            text-align: center;
            font-size: 2rem;
            letter-spacing: 0.45rem;
            margin-bottom: 0;
          }

          p {
            color: white;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.375rem;
            letter-spacing: 0.2rem;
          }
        }
      }

      &:hover,
      &:focus,
      &:active {

        .team_member_overlay {
          opacity: 1;
          transition: opacity 0.3s;

          &[data-aos="fade-team"] {

            &.aos-animate {
              opacity: 1;
              transition: opacity 0.3s;
            }
          }
        }
      }
    }
  }
}