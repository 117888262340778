// roundimage_text base

.workflow {
  max-width: 75rem;
  margin: auto;

  h2 {
    margin-bottom: 3em;
  }

  p {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .workflow_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 3rem;

    .workflow_item {
      position: relative;
      width: 14.2857%;
      text-align: center;

      img, .dummy {
        width: 100%;
        height: 9.85rem;
        object-fit: cover;
        border-radius: 50%;
        margin: auto;
        background-color: grey;
      }

      .workflow_overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 9.85rem;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);

        .workflow_overlay_text {
          display: none;
          position: relative;
          font-family: $fontcursive;
          font-size: 2rem;
          text-align: center;
          color: white;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover,
        &:focus,
        &:active {
          background-color: rgba(0,0,0,0);

          .workflow_overlay_text {
            display: block;
          }
        }

        &.active {
          background-color: rgba(0,0,0,0);
        }
      }

      h3 {
        text-align: center;
        text-transform: uppercase;
        margin-top: 3rem;
        margin-left: -3rem;
        margin-right: -3rem;
      }

      p {
        padding: 0 1rem;
        margin-top: 0;
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }

    .workflow_line {
      border-top: 1px solid grey;
      width: 14.2857%;
      margin-top: 5rem;
    }
  }
}