// bulletpoints base

.bulletpoints {
  padding: 6rem 1rem;

  .bulletpoints_container {
    max-width: 75rem;
    margin: 0 auto;

    h2 {

      em {
        margin-bottom: 1em;
      }
    }

    p {
      text-align: center;
    }

    .bulletpoints_items {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-top: 3rem;

      .bulletpoints_item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-width: 40%;
        width: 40%;
        max-width: 40%;
        margin: 1rem 0;

        &:nth-of-type(odd) {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
          -ms-flex-pack: end;
          justify-content: end;

          .bulletpoints_item_text {
            padding-right: 1.25rem;

            h3, p {
              text-align: right;
            }
          }
        }

        &:nth-of-type(even) {
          -ms-flex-pack: start;
          justify-content: start;

          .bulletpoints_item_text {
            padding-left: 1.25rem;

            h3, p {
              text-align: left;
            }
          }
        }

        .bulletpoints_item_icon {
          position: relative;
          border-radius: 50%;
          height: 6rem;
          width: 6rem;
          min-width: 6rem;

          img {
            width: 100%;
            height: 100%;
            padding: 1.5rem;
            object-fit: contain;
          }

          i {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            font-size: 3rem;
            line-height: 6rem;
            text-align: center;
            color: white;
          }
        }

        .bulletpoints_item_text {

          h3 {
            margin-bottom: 0.5rem;
            margin-top: 0;
          }

          p {
            line-height: 1.25;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}