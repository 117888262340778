// Bootstrap
@import "../../../node_modules/bootstrap/scss/functions.scss";
@import "../../../node_modules/bootstrap/scss/variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins.scss";
@import "../../../node_modules/bootstrap/scss/reboot.scss";
@import "../../../node_modules/bootstrap/scss/type.scss";
@import "../../../node_modules/bootstrap/scss/images.scss";
@import "../../../node_modules/bootstrap/scss/code.scss";
@import "../../../node_modules/bootstrap/scss/grid.scss";
@import "../../../node_modules/bootstrap/scss/tables.scss";
@import "../../../node_modules/bootstrap/scss/forms.scss";
@import "../../../node_modules/bootstrap/scss/buttons.scss";
@import "../../../node_modules/bootstrap/scss/transitions.scss";
@import "../../../node_modules/bootstrap/scss/dropdown.scss";
@import "../../../node_modules/bootstrap/scss/nav.scss";
@import "../../../node_modules/bootstrap/scss/navbar.scss";
@import "../../../node_modules/bootstrap/scss/card.scss";
@import "../../../node_modules/bootstrap/scss/media.scss";
@import "../../../node_modules/bootstrap/scss/carousel.scss";
@import "../../../node_modules/bootstrap/scss/modal.scss";
@import "../../../node_modules/bootstrap/scss/utilities.scss";

@import "font-awesome";
@import "../../Public/CSS/jquery.fancybox.css";

//general styles

@import "general SCSS files/variables";
@import "general SCSS files/color_schemes";
@import "fonts";
@import "general SCSS files/default_base";
@import "general SCSS files/klaro_styles";

@import "general SCSS files/menu_base";
@import "general SCSS files/footer_base";

//Content Elements

@import "ContentElements/audioplayer/audioplayer_base.scss";
@import "ContentElements/blog/blog_base.scss";
@import "ContentElements/blog_overview/blog_overview_base.scss";
@import "ContentElements/blog_detail/blog_detail_base.scss";
@import "ContentElements/bulletpoints/bulletpoints_base.scss";
@import "ContentElements/calendar/calendar_base.scss";
@import "ContentElements/calendar_widget/calendar_widget_base.scss";
@import "ContentElements/cover/cover_base.scss";
@import "ContentElements/divider_cover/divider_cover_base.scss";
@import "ContentElements/faq/faq_base.scss";
@import "ContentElements/form/form_base.scss";
@import "ContentElements/gallery/gallery_base.scss";
@import "ContentElements/header_and_button/header_and_button_base.scss";
@import "ContentElements/image_text/image_text_base.scss";
@import "ContentElements/image_textbox/image_textbox_base.scss";
@import "ContentElements/logo_gallery/logo_gallery_base.scss";
@import "ContentElements/map/map_base";
@import "ContentElements/reference_slider/reference_slider_base.scss";
@import "ContentElements/roundimage_text/roundimage_text_base.scss";
@import "ContentElements/service/service_base.scss";
@import "ContentElements/team/team_base.scss";
@import "ContentElements/text_image_divider/text_image_divider_base";

//callrotation

@import "callrotation/callrotation_base";