// header_and_button base

.header_and_button {
  padding: 2.5rem 1rem;

  .header_and_button_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 75rem;
    margin: 0 auto;

    h2 {
      margin: 0;
      font-size: 2.75rem;
      font-family: $fontextrabold;
      letter-spacing: 0;
      line-height: 1;
      color: white;
      text-align: left;
    }

    .hollow_button {
      font-size: 1.375rem;
      padding: 1rem 2rem;
      margin-left: 4rem;
    }
  }
}