// Pottkinder Colors

//font colors
$darkgreyfont:              #202126;
$lightgreyfont:             #b5b5b7;
$purplefont:                #f33965;
$redfont:                   #e21c34;
$orangefont:                #fea858;
$yellowfont:                #f9e866;
$greenfont:                 #b3eb50;
$bluefont:                  #6698cc;

//graphic element colors
$greyline:                  #818181;
$pinkline:                  #ef3763;

//gradients
$blackgradientoverlay:      linear-gradient(180deg, transparent, black);

//fonts
$fontcursive:                   AvallonAlt, cursive;
$fontregular:                   Montserrat-Light, sans-serif;
$fontlight:                     Montserrat-ExtraLight, sans-serif;
$fontbold:                      Montserrat-SemiBold, sans-serif;
$fontextrabold:                 Montserrat-ExtraBold, sans-serif;
$fontblack:                     Montserrat-Black, sans-serif;