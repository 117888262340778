// text_image_divider base

.textimagedivider {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 37.5rem 37.5rem 1fr;
    grid-template-columns: 1fr 37.5rem 37.5rem 1fr;

    .textimagedivider_image {
        position: relative;
        -ms-grid-column: 3;
        -ms-grid-column-span: 2;
        grid-column: 3/5;

        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .textimagedivider_text {
        position: relative;
        -ms-grid-column: 2;
        -ms-grid-column-span: 2;
        -ms-grid-row: 1;
        grid-column: 1/3;
        grid-row: 1;
        padding-left: 1rem;

        .textimagedivider_textcontent {
            max-width: 37.5rem;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            padding: 3rem 1rem 3rem 0;
            margin-right: 0;
            margin-left: auto;

            h2 {
                text-align: left;
                letter-spacing: 0;
                color: white;
                margin-bottom: 2.5rem;
                margin-top: 0;

                em {
                    text-align: left;
                    letter-spacing: 0;
                    color: white;
                }
            }

            p {
                color: white;
            }

            .hollow_button {
                margin-top: 2rem;
            }
        }
    }
}