// form base

.form {
  padding: 5rem 1rem;

  .line {
    height: 0.25rem;
    background-color: $lightgreyfont;
  }

  .round {
    height: 4rem;
    background-color: $lightgreyfont;
    border-radius: 50%;
    line-height: 4rem;
    text-align: center;
    font-family: $fontcursive;
    font-size: 2.5rem;
    color: white;
    margin: 0;
  }

  .form_container {
    max-width: 75rem;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;

    .form_content {
      width: 50%;
      max-width: 50%;
      min-width: 50%;

      h2 {
        text-align: left;
        margin-top: 0;
      }

      .form_text {
        text-align: left;
        margin-top: 2rem;
      }

      .form_status {
        display: grid;
        grid-template-columns: 1fr 4rem 2fr 4rem 2fr 4rem 1fr;
        align-items: center;
        margin-top: 4rem;
      }

      .formular {
        padding-top: 4rem;

        h2 {
          margin-bottom: 2rem;
          font-family: $fontblack;
          color: $darkgreyfont;
          font-size: 1.5rem;
        }

        label {
          margin-top: 0.5rem;
          color: $darkgreyfont;
        }

        input, textarea {
          padding: 2rem;
          border-radius: 0;
          border: 0.125rem solid $lightgreyfont;
          font-family: $fontregular;
        }

        textarea {
          height: 10rem;
        }
      }

      .actions {
        position: relative;

        nav {
          position: relative;
        }
      }
    }

    .form_image {
      width: 50%;
      max-width: 50%;
      min-width: 50%;
      padding-left: 5rem;

      img {
        width: 100%;
        height: 100%;
        max-height: 36rem;
        object-fit: cover;
      }
    }
  }
}