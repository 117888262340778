//calendar_widget base

.calendar_widget {
  padding: 1rem;
  width: 100%;
  max-width: 56rem;
  margin: 2rem auto;

  table {
    width: 100%;

    .calendar_head_month {
      color: white;
      height: 3rem;
      padding-left: 1rem;
      background-color: transparent;
      text-align: center;
    }

    .calendar_head {
      position: relative;
      color: white;
      height: 3rem;
      width: 14.285714286%;
      text-align: center;
      background-color: transparent;

      a {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 3rem;
        color: white;
        text-decoration: none;
        line-height: 3rem;
        transition: background-color 0.25s;

        &.left {
          transform: rotate(180deg);
          right: unset;
          left: 0;
        }

        &:hover,
        &:focus {
          background-color: rgba(255,255,255,0.2);
          transition: background-color 0.25s;
        }
      }
    }

    td {
      text-align: center;
      padding: 0.25rem;
      background-color: transparent;

      &.calendar_day {
        background-color: rgba(255,255,255,1);
        padding: 0.75rem 0.75rem 0.25rem 0.75rem;
      }

      &.calendar_entry {
        padding: 0;

        a {
          display: block;
          width: 100%;
          height: 100%;
          color: white;
          text-decoration: none;
          padding: 0.25rem 0.5rem;
          transition: background-color 0.25s;

          &:hover,
          &:focus,
          &:active {
            transition: background-color 0.25s;
            background-color: rgba(255,255,255,0.2);
          }

          &.active {
            background-color: rgba(255,255,255,0.75);
            font-family: $fontextrabold;
            color: $darkgreyfont;
          }
        }

        &.holiday {

          a {
            opacity: 0.5;
            cursor: unset;

            &:hover,
            &:focus,
            &:active {
              background-color: rgba(255,255,255,0);
              color: white;
            }

            &.active {
              background-color: rgba(255,255,255,0);
              font-family: $fontregular;
              color: white;
            }
          }
        }
      }
    }
  }
}

.calendar_form {
  display: none;
  padding: 2rem 1rem 1rem;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;

  #calendarform {

    h3 {
      text-align: center;
      margin-bottom: 3rem;

      span {
        padding: 0 0.5rem;

      }
    }

    .calendarform_container {
      width: 100%;
      max-width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
     // -ms-flex-align: start;
      //align-items: start;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 2rem;

      .calendarform_times {
        display: grid;
        grid-template-columns: auto 1fr;
        width: 23rem;
        max-width: 100%;
        padding-right: 5rem;
        align-items: center;
        margin-bottom: 3rem;
        margin-top: 0.25rem;

        >p {
          text-align: center;
          margin: 0 2rem 0 1rem;
          font-family: $fontbold;
          font-size: 1.5rem;
          line-height: 3rem;
          color: white;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
        }

        >div {
          padding: 0.5rem 0;
          //height: 8rem;

          input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            display: none;
          }

          label {
            border: 1px solid $greyline;
            width: 12rem;
            padding: 0.5rem;
            margin: 0.5rem 0;
            cursor: pointer;
          }

          input + label {

            &:hover,
            &:focus,
            &:active {
              background-image: $purplegradientoverlay;
              border: 1px solid white;
            }
          }

          input:checked + label {
            background-image: $purplegradient;
            color: white;
            border: 1px solid white;
          }
        }
      }

      .calendarform_data {
        opacity: 0;
        transition: opacity 0.25s;
        width: 28rem;
        max-width: 100%;
        margin: 0;
        padding-bottom: 2.25rem;

        input {
          display: block;
          margin-bottom: 1.5rem;
          width: 100%;
          padding: 0.5rem;
          border: 1px solid $greyline;
          border-radius: 0;
        }
        label {
          display: block;
        }
        textarea {
          display: block;
          //margin-bottom: 1.5rem;
          width: 100%;
          height: 10rem;
          padding: 0.5rem;
          border: 1px solid $greyline;
          border-radius: 0;
          resize: none;
        }
      }
    }
  }

  input[type="button"] {
    display: block;
    margin-bottom: 3rem;
  }
}