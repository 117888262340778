// audioplayer base

.audioplayer {
  padding: 4rem 1rem;

  .audioplayer_container {
    max-width: 75rem;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;

    .audioplayer_player {
      position: relative;
      width: 40%;
      height: 100%;
      --seek-before-width: 0%;
      --volume-before-width: 100%;
      --buffered-width: 0%;
      max-width: 500px;
      padding: 1.5rem;
      margin: 0 auto;
      background-color: $darkgreyfont;

      .player_upper {
        display: -ms-flexbox;
        display: flex;

        .audio_text {
          color: white;
          padding-right: 1rem;
          min-height: 7.5rem;
          width: 100%;

          p {
            margin: 0;
          }
        }

        .volume_control {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: start;
          justify-content: start;
          width: 3rem;
          //overflow: hidden;

          output {
            margin-bottom: 0.5rem;
            color: white;
          }

          span {
            margin-top: 1rem;
            padding: 0 0.5rem;
          }

          #volume-slider {
            height: 5rem;
            width: 5rem;
            transform: rotate(-90deg);
          }

          #volume-slider::-webkit-slider-runnable-track {
            background: rgba(0, 0, 0, 0.2);
          }

          #volume-slider::-moz-range-track,
          #volume-slider::-ms-fill-upper {
            background: rgba(0, 0, 0, 0.5);
          }

          #volume-slider::before {
            width: var(--volume-before-width);
            background: $purplegradient;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          #mute-icon {
            display: block;
            margin: 0 0.5rem;
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;

            i {
              display: block;
              font-size: 1.5rem;
              margin: 0.5rem auto 0;
            }
          }
        }
      }

      .player_lower {
        display: -ms-flexbox;
        display: flex;
        margin-top: 2rem;

        .play-icon {
          display: block;
          margin-right: 1rem;
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;

          i {
            font-size: 1.5rem;
          }
        }

        .player_progress {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          color: white;

          span {
            padding: 0 0.5rem;
          }

          input {
            width: 100%;
          }
        }
      }

      path {
        stroke: #000000;
      }

      .time {
        display: inline-block;
        text-align: center;
        font-size: 1rem;
      }

      output {
        display: inline-block;
        text-align: center;
        font-size: 1rem;
      }

      input[type="range"] {
        position: relative;
        -webkit-appearance: none;
        margin: 0;
        padding: 0;
        height: 19px;
        outline: none;
        background-color: transparent;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5) var(--buffered-width), rgba(0, 0, 0, 0.2) var(--buffered-width));

      }

      input[type="range"]::before {
        position: absolute;
        content: "";
        top: 8px;
        left: 0;
        width: var(--seek-before-width);
        height: 3px;
        background: $purplegradient;
        cursor: pointer;
      }

      input[type="range"]::-webkit-slider-thumb {
        position: relative;
        -webkit-appearance: none;
        box-sizing: content-box;
        border: 1px solid #000000;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
        margin: -7px 0 0 0;
      }

      input[type="range"]:active::-webkit-slider-thumb {
        transform: scale(1.2);
        background-color: $gradientpurple2;
      }

      input[type="range"]::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5) var(--buffered-width), rgba(0,0,0, 0.2) var(--buffered-width));
      }

      input[type="range"]::-moz-range-progress {
        background: $purplegradient;
      }

      input[type="range"]::-moz-focus-outer {
        border: 0;
      }

      input[type="range"]::-moz-range-thumb {
        box-sizing: content-box;
        border: 1px solid #000000;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
      }

      input[type="range"]:active::-moz-range-thumb {
        transform: scale(1.2);
        background-color: $gradientpurple2;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        background: transparent;
        border: solid transparent;
        color: transparent;
      }

      input[type="range"]::-ms-fill-lower {
        background: $purplegradient;
      }

      input[type="range"]::-ms-fill-upper {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5) var(--buffered-width), rgba(0, 0, 0, 0.2) var(--buffered-width));
      }

      input[type="range"]::-ms-thumb {
        box-sizing: content-box;
        border: 1px solid #000000;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
      }

      input[type="range"]:active::-ms-thumb {
        transform: scale(1.2);
        background-color: $gradientpurple2;
      }
    }

    .audioplayer_text {
      width: 60%;
      padding-left: 4rem;

      h2 {
        text-align: left;
        margin-bottom: 2rem;
        margin-top: 0;

        em {
          letter-spacing: 0;
        }
      }

      ul {
        margin-left: 1rem;
      }

      .standard_button {
        margin-top: 3rem;
      }
    }
  }
}

body.color-0 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $purplegradient;
  }
  input[type="range"]::before {
    background: $purplegradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $purplegradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientpurple2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientpurple2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientpurple2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $purplegradient;
    }
    #volume-slider::before {
      background: $purplegradient;
    }
  }
}

body.color-1 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $redgradient;
  }
  input[type="range"]::before {
    background: $redgradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $redgradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientred2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientred2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientred2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $redgradient;
    }
    #volume-slider::before {
      background: $redgradient;
    }
  }
}

body.color-2 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $orangegradient;
  }
  input[type="range"]::before {
    background: $orangegradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $orangegradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientorange2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientorange2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientorange2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $orangegradient;
    }
    #volume-slider::before {
      background: $orangegradient;
    }
  }
}

body.color-3 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $yellowgradient;
  }
  input[type="range"]::before {
    background: $yellowgradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $yellowgradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientyellow2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientyellow2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientyellow2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $yellowgradient;
    }
    #volume-slider::before {
      background: $yellowgradient;
    }
  }
}

body.color-4 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $greengradient;
  }
  input[type="range"]::before {
    background: $greengradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $greengradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientgreen2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientgreen2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientgreen2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $greengradient;
    }
    #volume-slider::before {
      background: $greengradient;
    }
  }
}

body.color-5 .audioplayer .audioplayer_container .audioplayer_player {

  input[type="range"]::-moz-range-progress {
    background: $bluegradient;
  }
  input[type="range"]::before {
    background: $bluegradient;
  }
  input[type="range"]::-ms-fill-lower {
    background: $bluegradient;
  }
  input[type="range"]:active::-webkit-slider-thumb {
    background-color: $gradientblue2;
  }
  input[type="range"]:active::-moz-range-thumb {
    background-color: $gradientblue2;
  }
  input[type="range"]:active::-ms-thumb {
    background-color: $gradientblue2;
  }
  .player_upper .volume_control {
    #volume-slider::-moz-range-track,
    #volume-slider::-ms-fill-upper {
      background: $bluegradient;
    }
    #volume-slider::before {
      background: $bluegradient;
    }
  }
}


