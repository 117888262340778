// blog_overview base

.blog_overview {
  max-width: 75rem;
  margin: 6rem auto 9rem;

  .blog_filter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 3rem 1rem 2rem;
    margin: 0;

    a {
      display: block;
      margin: 0.25rem 3rem 0.25rem 0;

      span {
        font-family: $fontbold;
        font-size: 1.125rem;
        white-space: nowrap;
        text-transform: uppercase;
        color: $greyline;
        transition: color 0.25s;

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
          color: $darkgreyfont;
          transition: color 0.25s;
        }
      }

      i {
        display: none;
        margin-right: 0.375rem;
        transition: color 0.25s;
      }

      &.active {

        span {
          color: $darkgreyfont;
          transition: color 0.25s;
        }

        i {
          display: inline;
          transition: color 0.25s;
        }
      }
    }
  }

  .blog_overview_blocks {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 3.5rem;
    padding: 0 0.6rem;

    .blog_overview_block {
      max-width: calc(50% - 0.8rem);
      width: calc(50% - 0.8rem);
      min-width: calc(50% - 0.8rem);
      display: block;
      position: relative;
      color: white;
      margin: 0.4rem;

      .blog_overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &:hover,
      &:focus,
      &active {
        text-decoration: none;

        .blog_overview_blocks_content {
          background-image: none;
        }

        .blog_overlay {
          opacity: 1;
        }
      }

      &>img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .blog_overview_blocks_content {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 18rem 1.5rem 6.5rem;
        background-image: $blackgradientoverlay;
        z-index: 2;
        max-height: 100%;
        overflow: hidden;

        svg text {
          font-family: $fontregular;
          font-size: 1.125rem;
          text-transform: uppercase;
          color: white;
        }

        h3 {
          font-family: $fontblack;
          font-size: 1.875rem;
          text-transform: uppercase;
          margin-top: 1.5rem;
          margin-bottom: 0;
        }

        &>div {
          position: absolute;
          bottom: 2rem;
          margin-top: 1rem;

          &>img {
            display: inline-block;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            margin-right: 0.8rem;
          }

          p {
            display: inline-block;
            font-family: $fontextrabold;
            font-size: 1.125rem;
            vertical-align: bottom;
            margin-bottom: 0;
            line-height: 3.5rem;
          }
        }
      }
    }
  }

  &>a {
    display: block;
    text-align: center;
  }
}